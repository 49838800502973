.modal {

  .modal-loader {
    position: absolute;
    background: rgba(255,255,255,0.9);
    width: 97%;
    height: 97%;
    z-index: 100;
    margin: 0;

    img {
      width: 100px;
      margin-top: 130px;
    }
  }

  .modal-cancel-subscription {

    .modal__header {
      margin-left: 15px;

      .modal__investor-pic {
        width: auto;
        height: auto;
        max-width: 60px;
        max-height: 60px;
        float: left;
        border-radius: 40px;
        margin-right: 20px;
        margin-top: -10px;
      }
    }

    .subscription-boxes {
      margin: 55px 15px 10px;
      transition: 1s;

      > div:first-child {
        padding-left: 0;
      }
      > div:last-child {
        padding-right: 0;
      }

      .subscription-boxes-box {
        padding: 95px 28px 60px;
        position: relative;
        overflow: hidden;
//        cursor: pointer;
        width: 100%;
        min-height: 485px;

        &#switch-plan {
          background: linear-gradient(129.9deg, #59ACDE 12.01%, #97ABFA 72.74%, #A5B3FB 72.74%), #97ABFA;
        }

        &#unsubscribe {
          border: 1px solid #828F96;

          h2 {
            color: #243143;
          }
        }

        h2 {
          font-weight: 700;
          font-size: 26px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          margin-bottom: 30px;
        }

        .subscription-boxes-title {
          color: white;
          font-size: 24px;
          font-weight: 500;
        }

        .subscription-boxes-subtitle {
          font-size: 16px;
          line-height: 25px;
          text-align: center;
          color: #243143;
          max-width: 420px;
          margin: auto;
        }

        .subscription-boxes-bullets {
          font-size: 16px;
          line-height: 25px;
          color: #243143;
          max-width: 400px;
          margin: auto;
        }

        button {
          margin: 35px auto 0;
        }

        &:hover {
          box-shadow: 0 1px 7px #89899b;
        }
      }
    }
  }


  .modal-feedback {
    .numberOfInvestors {
      .material-form__field {
        float: right;
        width: auto;
      }
    }
    .flex-direction-initial {
      flex-direction: initial !important;

      label:first-child {
        margin-left: auto;
      }
    }
    .textarea-feedback textarea:first-child {
      min-height: 70px;
    }
    .MuiRadio-colorSecondary {
      &.Mui-checked {
        color: #97ABFD;
      }
    }
  }
}
